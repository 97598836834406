$font-dir: "../font/roboto/files";
@import '../../node_modules/@fontsource/roboto/scss/mixins';

$font-dir: '../../node_modules/@fontsource/roboto/files' !default;

@include fontFace(
  $weight: 400,
  $display: swap,
  $fontDir: $font-dir
);

@include fontFace(
  $weight: 700,
  $display: swap,
  $fontDir: $font-dir
);
